@charset "UTF-8";
/*---------------------------------
	bit Common CSS 2018.2
	CSS inoue Document 2018.02
	Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/*####################　共通設定　####################*/
* {
  box-sizing: border-box; }

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both; }

body {
  line-height: 1.5;
  text-align: center;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  /* font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif; */
  /* font-family: helvetica,'arial black',arial,sans-serif; */
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  letter-spacing: 0.045em;
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  font-weight: 500;
  /*font-weight: 500;  ※游明朝の時は400*/
  color: #222222; }

p {
  text-align: justify;
  text-justify: inter-ideograph;
  line-height: 1.8;
  font-size: 16px; }

a {
  color: #222222;
  text-decoration: none; }

hr, .showBox {
  display: none; }

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left; }

#Main {
  min-height: 500px;
  _height: 500px; }

#Footer {
  margin: 0 auto;
  text-align: left; }

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both; }

/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
pc,sp共部分
-------------------------------------*/
/*#TopicPath {
  text-align: left;
  ol {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    list-style: none;
    line-height: 1;
    font-family: helvetica,'arial black',arial,sans-serif;
    color: #222;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      display: inline;
      background: url(../img/contents/★アロー画像★) no-repeat left center;
      background-size: px auto;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-weight: 500;
      color: #222;
      &.home {
        padding-left: 0;
        background: none;
      }
      a {
        text-decoration: none;
      }
    }
  }
}*/
/*#################### パーツ ####################*/
.hTitle {
  text-align: center;
  letter-spacing: 0.1em;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: #111;
  line-height: 1.8; }

.enTitle {
  display: block;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #a4051a;
  letter-spacing: 0.2em; }

.time {
  font-family: "Lato", sans-serif;
  letter-spacing: 0.2em; }

.moreLinkBtn {
  border-radius: 5px;
  display: block;
  width: 195px;
  text-align: center;
  padding: 9px 0;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  letter-spacing: 0.18em;
  margin: 0 auto; }

.redBtn {
  background: url(../img/00_arrow_white.png) no-repeat right 15px top 16px;
  background-size: 40px auto;
  background-color: #a4051a; }

.whiteBtn {
  background: url(../img/00_arrow_red.png) no-repeat right 15px top 16px;
  background-size: 40px auto;
  background-color: #fff;
  color: #a4051a; }

.blackBtn {
  background: url(../img/00_arrow_white.png) no-repeat right 15px top 16px;
  background-size: 40px auto;
  background-color: #222222; }

.blackBtnPlus {
  background: url(../img/00_plus_white.png) no-repeat right 15px center;
  background-size: 20px auto;
  background-color: #222222; }

.leadTitle {
  color: #a4051a;
  font-size: 24px;
  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  text-align: center;
  line-height: 1.8; }

.subTitle {
  font-size: 16px;
  display: block;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif; }

.segLabel {
  background-color: #222222;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 9px 22px;
  text-align: center;
  letter-spacing: 0.25em; }

.relatedPosts {
  background-color: #f0f0f0;
  padding: 40px 15px 50px; }

.relatedPosts .enTitle {
  margin-left: 0; }

.relatedPosts .relatedPosts_item {
  width: 48%;
  background-color: #fff;
  margin-right: 2%;
  margin-bottom: 15px;
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06); }

.relatedPosts .relatedPosts_item:nth-of-type(2n) {
  margin-right: 0;
  float: right; }

.relatedPosts .relatedPosts_item .imgBox {
  position: relative;
  padding: 14px 0 0; }

.relatedPosts .relatedPosts_item .imgBox .label {
  background-color: #222222;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 7px 3px 9px;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 8px;
  letter-spacing: 0.25em; }

.relatedPosts .relatedPosts_item .imgBox .thumb {
  width: 100%; }

.relatedPosts .relatedPosts_item .infoBox {
  padding: 0 15px 30px; }

.relatedPosts .relatedPosts_item .infoBox dl {
  width: calc(100% + 15px); }

.relatedPosts .relatedPosts_item .infoBox .type {
  color: #a4051a;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  display: inline-block; }

.relatedPosts .relatedPosts_item .infoBox dd {
  vertical-align: middle;
  display: inline-block; }

.relatedPosts .relatedPosts_item .infoBox dd.morningIcon {
  background: url(../img/02_01_ico_morning.png) no-repeat center center;
  background-size: 15px auto;
  width: 15px;
  height: 15px;
  margin-bottom: 2px; }

.relatedPosts .relatedPosts_item .infoBox dd.daytimeIcon {
  background: url(../img/02_01_ico_daytime.png) no-repeat center center;
  background-size: 16px auto;
  width: 16px;
  height: 15px; }

.relatedPosts .relatedPosts_item .infoBox dd.nightIcon {
  background: url(../img/02_01_ico_night.png) no-repeat center center;
  background-size: 13px auto;
  width: 13px;
  height: 15px; }

.relatedPosts .relatedPosts_item .infoBox .recommend {
  font-size: 14px; }

.relatedPosts .relatedPosts_item .infoBox .title {
  font-size: 16px;
  font-weight: 700; }

.relatedPosts .relatedPosts_item .infoBox .convLinkBtn {
  margin-top: 10px; }

.detailLinkBtn a {
  background-color: #a4051a;
  color: #fff;
  font-size: 14px;
  padding: 7px 0;
  display: block;
  text-align: center;
  border-radius: 5px; }

.convLinkBtn b {
  background-color: #a4051a;
  color: #fff;
  font-size: 14px;
  padding: 7px 0;
  display: block;
  text-align: center;
  border-radius: 5px; }

/*hover*/
.moreLinkBtn:hover {
  text-decoration: none; }

.detailLinkBtn a:hover {
  text-decoration: none; }

.font-lato {
  font-family: 'Lato', sans-serif;
  font-weight: 400; }

form table {
  width: 100%;
  margin-bottom: 30px; }

form table tr {
  border-bottom: 1px solid #e1e1e1; }

form table tr:nth-of-type(2) th:before, form table tr:nth-of-type(5) th:before, form table tr:nth-of-type(6) th:before, form table tr:nth-of-type(7) th:before {
  content: '任意';
  background-color: #888888; }

form table th {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-top: 1.094em;
  margin-bottom: 6px;
  font-size: 16px; }

form table th:before {
  position: absolute;
  content: '必須';
  width: 34px;
  height: 18px;
  background-color: #a4051a;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.2em;
  line-height: 18px;
  text-align: center;
  padding-left: 0.2em;
  top: 2.5px;
  left: 0; }

form table td {
  display: block;
  padding-bottom: 21px;
  font-size: 16px; }

form input:not([type="submit"]), form textarea, form select {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  padding: 0 14px; }

form input:not([type="submit"]):focus, form textarea:focus, form select:focus {
  border-color: #f36e8d; }

form input[type="submit"] {
  width: 225px;
  height: 60px;
  background-color: #a4051a;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
  line-height: 60px;
  text-align: center;
  border-radius: 5px; }

form textarea {
  height: 210px;
  resize: vertical;
  padding: 12px 14px;
  margin: 5px 0 -3px; }

form .selector {
  width: 100% !important;
  position: relative; }

form .selector span {
  display: none; }

form select {
  background-image: url(../img/07_00_select_red.png);
  background-position: right 14px center;
  background-repeat: no-repeat;
  background-size: 11px auto; }

form .btnBox p {
  text-align: center; }

form .btnBox span {
  display: inline-block; }

.slider_wrapper {
  width: 100%;
  position: relative; }

.slide {
  width: 100%;
  background-size: cover;
  background-position: center; }

.slider_dot {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7px;
  bottom: 12px; }

.slider_dot li {
  width: 7px;
  height: 7px; }

.slider_dot li + li {
  margin-left: 5px; }

.slider_dot li.slick-active button {
  background-color: #a4051a; }

.slider_dot button {
  width: 100%;
  height: 100%;
  font-size: 0;
  border: 1px solid #a4051a;
  border-radius: 50%;
  position: relative;
  top: -14px; }

/*#################### ヘッダー ####################*/
#Header {
  position: fixed;
  z-index: 3000;
  width: 100%;
  background-color: #fff;
  height: 60px; }

.fixed #Header {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06); }

#ContBoxHeader {
  position: relative;
  background-color: #fff; }

#ContBoxHeader h1 {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 203px;
  height: 31px;
  background: url(../img/00_logo.png) no-repeat top left;
  background-size: 203px auto; }

#ContBoxHeader h1 a {
  display: block;
  height: 100%;
  text-indent: -9999px; }

#MenuButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background: url(../img/00_menu.png) no-repeat center top 20px;
  background-size: 25px auto;
  background-color: #a4051a;
  cursor: pointer;
  text-indent: -9999px; }

#MenuButtonClose {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 60px;
  height: 60px;
  background: url(../img/00_menu_close.png) no-repeat center top 20px;
  background-size: 19px auto;
  background-color: #a4051a;
  cursor: pointer;
  text-indent: -9999px; }

#HeaderMenu_sp {
  overflow-y: scroll;
  position: fixed;
  top: -900%;
  right: 0;
  z-index: 2000;
  width: 100%;
  text-align: center;
  -webkit-transition: top .5s;
  transition: top .5s; }

#HeaderContactListWarp {
  background-color: #a4051a; }

#Wall {
  z-index: 1500;
  display: none; }

.active #MenuButtonClose {
  display: block; }

.active #MenuButton {
  display: none; }

.active #HeaderMenu_sp {
  top: 0;
  padding-top: 60px;
  height: 100%; }

.active #Wall {
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #fff; }

.active .closeTrigger {
  height: calc(100vh - 667px); }

/*#################### メニュー ####################*/
.menu_sp .menu_spList li {
  background-color: #a4051a;
  text-align: left; }

.menu_sp .menu_spList li.contList span {
  display: block;
  color: #fff;
  letter-spacing: 0.07em; }

.menu_sp .menu_spList li.contList {
  border-top: 1px solid #7c0112; }

.menu_sp .menu_spList li.contList:first-of-type {
  border-top: 1px solid #7c0112; }

.menu_sp .menu_spList li.contList:last-of-type {
  border-bottom: 1px solid #7c0112; }

.menu_sp .menu_spList li.contList .menuClick {
  background: url(../img/00_plus_white.png) no-repeat right 15px center;
  background-size: 20px auto;
  padding: 24px 15px 21px;
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700; }

.menu_sp .menu_spList li.contList .menuClick.active {
  background: url(../img/00_minus_white.png) no-repeat right 15px center;
  background-size: 20px auto; }

.menu_sp .menu_spList li.contList span > a {
  padding: 24px 15px 21px;
  max-width: 800px;
  margin: 0 auto; }

.menu_sp .menu_spList li.contList span a {
  display: block;
  background: url(../img/00_arrow02_white.png) no-repeat right 20px center;
  background-size: 10px auto;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff; }

.menu_sp .menu_spList .guideList {
  background-color: #8f0a1c;
  display: none; }

.menu_sp .menu_spList .guideList li {
  /*border-bottom: none;*/
  background-color: transparent;
  border-bottom: 1px solid #7c0112; }

.menu_sp .menu_spList .guideList li:first-of-type {
  border-top: 1px solid #7c0112; }

.menu_sp .menu_spList .guideList li:last-of-type {
  border-bottom: none; }

.menu_sp .menu_spList .guideList li:last-of-type a {
  border-bottom: none; }

.menu_sp .menu_spList .guideList li a {
  border-bottom: none;
  padding: 5px 0 5px 40px;
  background: url(../img/00_arrow03_white.png) no-repeat right 20px center;
  background-size: 9px auto;
  display: block;
  font-size: 16px;
  /*font-weight: bold;*/
  color: #fff;
  /*border-bottom: 1px solid #7c0112;*/
  padding: 13px 30px 12px;
  letter-spacing: 0.04em;
  max-width: 800px;
  margin: 0 auto; }

.menu_sp .contactList {
  background-color: #a4051a;
  padding: 40px 15px;
  text-align: center; }

.menu_sp .contactList li {
  margin-bottom: 23px; }

.menu_sp .contactList li .contactBtn span {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.06em; }

.menu_sp .contactList li .contactBtn span::before {
  content: "";
  display: inline-block;
  position: absolute; }

.menu_sp .contactList li .contactBtn span::after {
  content: "";
  display: inline-block;
  position: absolute; }

.menu_sp .contactList .netOrder .contactBtn span {
  margin-left: 25px; }

.menu_sp .contactList .netOrder .contactBtn span::before {
  width: 33px;
  height: 25px;
  background: url(../img/00_ico_cart.png) no-repeat center center;
  background-size: 33px auto;
  top: -3px;
  left: -40px; }

.menu_sp .contactList .netOrder .contactBtn span::after {
  width: 14px;
  height: 14px;
  background: url(../img/00_ico_blank.png) no-repeat center center;
  background-size: 14px auto;
  top: 2px;
  right: -17px; }

.menu_sp .contactList .mailContact .contactBtn span {
  margin-left: 40px; }

.menu_sp .contactList .mailContact .contactBtn span::before {
  width: 27px;
  height: 20px;
  background: url(../img/00_ico_mail.png) no-repeat center center;
  background-size: auto 20px;
  top: 1px;
  left: -37px; }

_::-webkit-full-page-media, _:future, :root .menu_sp .contactList .mailContact .contactBtn span::before {
  top: -2px; }

.menu_sp .contactList .telOrder .contactBtn span::before,
.menu_sp .contactList .Fax .contactBtn span::before {
  width: 35px;
  height: 20px;
  background: url(../img/00_ico_freedial.png) no-repeat center center;
  background-size: 35px auto;
  top: 0;
  left: -40px; }

_::-webkit-full-page-media, _:future, :root .menu_sp .contactList .telOrder .contactBtn span::before {
  top: -2px; }

_::-webkit-full-page-media, _:future, :root .menu_sp .contactList .Fax .contactBtn span::before {
  top: -3px; }

.menu_sp .contactList .telContact .contactBtn span::before {
  width: 23px;
  height: 25px;
  background: url(../img/00_ico_tel.png) no-repeat center center;
  background-size: 23px auto;
  top: -2px;
  left: -32px; }

_::-webkit-full-page-media, _:future, :root .menu_sp .contactList .telContact .contactBtn span::before {
  top: -4px; }

.menu_sp .contactList .telOrder .contactBtn a:hover,
.menu_sp .contactList .telContact .contactBtn a:hover {
  text-decoration: none; }

.menu_sp .contactList .telOrder .contactBtn span b,
.menu_sp .contactList .telContact .contactBtn span b {
  text-decoration: underline;
  font-family: 'Lato', sans-serif;
  font-weight: bold; }

.menu_sp .contactList .Fax .contactBtn a {
  pointer-events: none; }

.menu_sp .contactList .Fax .contactBtn span b {
  font-family: 'Lato', sans-serif;
  font-weight: bold; }

.menu_sp .contactList dt {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 9px;
  letter-spacing: 0.08em; }

.menu_sp .contactList .contactBtn a {
  background-color: #c55362;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  background-color: #c55362;
  display: block;
  padding: 24px 0; }

.menu_sp .contactList .contactBtn time {
  display: block;
  color: #fff;
  font-size: 14px; }

.menu_sp .contactList .telOrder .contactBtn a {
  padding: 15px 0;
  margin-bottom: 7px; }

.menu_sp .contactList .telOrder dd span {
  margin-left: 42px; }

.menu_sp .contactList .telOrder dd span b {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.04em; }

.menu_sp .contactList .telContact .contactBtn a {
  padding: 15px 0;
  margin-bottom: 7px; }

.menu_sp .contactList .telContact dd span {
  margin-left: 33px; }

.menu_sp .contactList .telContact dd span b {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.04em; }

.menu_sp .contactList .telOrder dd:nth-of-type(2) {
  color: #fff;
  font-size: 14px; }

.menu_sp .contactList .Fax .contactBtn a {
  padding: 15px 0;
  margin-bottom: 7px; }

.menu_sp .contactList .Fax dd span {
  margin-left: 42px; }

.menu_sp .contactList .Fax dd span b {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.04em; }

/*#################### メインイメージ ####################*/
#MainImg {
  padding-top: 60px; }

#MainImg #MainImgInner {
  position: relative;
  width: 100%;
  height: 215px; }

#MainImg #MainImgInner h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding: 12px 35px 8px;
  background-color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #222222;
  letter-spacing: .04em;
  line-height: 1.6;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

/*#################### フッター ####################*/
#Footer {
  background-color: #a4051a; }

#Footer.menu_sp .contactList {
  padding-bottom: 20px; }

#Footer .copyRight {
  padding: 32px 0;
  background-color: #a4051a;
  text-align: center;
  letter-spacing: .06em;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #fff; }

#Footer .copyRight h2 {
  display: none; }

#Footer .copyRight small {
  display: block; }

#Footer .copyRight address {
  display: block; }

/* アコーディオン用css */
/*#################### PC ####################*/
@media print, screen and (min-width: 768px) {
  p {
    /* margin: 0 0 15px;
    line-height: 15px; */ }

  a[href^="tel"] {
    text-decoration: none; }

  a[href^="tel"]:hover {
    cursor: text; }

  a:hover {
    text-decoration: underline; }

  .click {
    cursor: pointer; }

  .pc_display,
  .disp_pc {
    display: block; }

  .sp_display,
  .sp_display_inline,
  .disp_sp {
    display: none; }

  /*####################　枠設定　####################*/
  .innerBasic {
    margin: 0 auto;
    /*max-width: 1000px;*/
    max-width: 800px; }

  /*####################　パーツ　####################*/
  .menu_sp .contactList .telOrder .contactBtn a,
  .menu_sp .contactList .telContact .contactBtn a {
    pointer-events: none; }

  .relatedPosts .relatedPosts_item a:hover {
    text-decoration: none; }

  /*####################　ヘッダ　####################*/
  /*####################　グローバルナビ　####################*/
  /* #GlobalNav{}
  #MenuButton {
    display: none !important;
  } */
  /*####################　サブメニュー　####################*/
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  /* #TopicPath {
    margin: px auto 0;
    width: 1000px;
    ol li {
      padding-left: 15px;
      font-size: 15px;
      a:hover {
        text-decoration: underline;
      }
    }
  } */
  /*　メイン
  -------------------------------------*/
  .menu_sp .contactList .telOrder .contactBtn a,
  .menu_sp .contactList .telContact .contactBtn a {
    pointer-events: none; }

  /*####################　フッタ　####################*/ }
/*#################### SP ####################*/
@media screen and (max-width: 767px) {
  .pc_display,
  .disp_pc {
    display: none; }

  .sp_display,
  .disp_sp {
    display: block; }

  .sp_display_inline {
    display: inline; }

  /*####################　枠設定　####################*/
  /*####################　ヘッダ　####################*/
  /*####################　グローバルナビ　####################*/
  /* #GlobalNav{
    display:none;
  } */
  /* #MenuButton {
    cursor: pointer;
    position: absolute;
    z-index: 60;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center px;
    background-image: url();
    background-size: px auto;
    background-color: #222;
    line-height: 1;
    text-align: center;
    font-size: 15px;
    color: #222;
  } */
  /* .active #MenuButton {
    background-position: center px;
    background-image: url();
    background-size: px auto;
  }
  #HeaderMenu_sp {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 50;
    width: 100%;
    text-align: center;
    transition: top 0.5s;
  }
  .active #HeaderMenu_sp {
    top: 15px;
    transition: top 0.5s;
  } */
  /*####################　サブメニュー　####################*/
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  /* #TopicPath {
    margin: px auto 0;
    padding: 0 px;
    ol li {
      padding-left: 15px;
      font-size: 15px;
    }
  } */
  /*　メイン
  -------------------------------------*/
  /*####################　フッタ　####################*/ }
/*end*/
